import { Map } from 'immutable';
import { saveAs } from './FileSaver';

//import { injectIntl } from 'react-intl';

export function isEmptyObj(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function clearToken() {
  const dashVideo = localStorage.getItem('vvz-dash-video');
  localStorage.clear();
  localStorage.setItem('vvz-dash-video', dashVideo);
}

export function kindList() {
  return [
    { title: 'Descripción proyecto Audiovisual Largo', handle: 'MOVIE' },
    { title: 'Descripción proyecto Audio', handle: 'RADIOSPOT' },
    { title: 'Descripción proyecto Audiovisual', handle: 'TVSPOT' }
  ];
}

export function kindTitle(key) {
  const kinds = [
    { title: 'Dubbing', handle: 'MOVIE' },
    { title: 'Audio', handle: 'RADIOSPOT' },
    { title: 'Audiovisual', handle: 'TVSPOT' }
  ];
  const selected = kinds.filter((kind) => kind.handle === key);
  return selected.length > 0 ? selected[0].title : '';
}

export function supportedFormats(fieldName, kind) {
  let expr;
  if (fieldName === 'text') {
    expr =
      kind && kind === 'MOVIE'
        ? '(.*?).(txt|TXT|CSV|csv)$'
        : '(.*?).(txt|TXT)$';
  } else if (fieldName === 'pronunciation') {
    expr = '(.*?).(CSV|csv)$';
  } else if (fieldName === 'video') {
    expr = '(.*?).(mp4|MP4|MPEG|mpeg)$';
  } else if (fieldName === 'fx') {
    expr = '(.*?).(mp3|MP3|wav|WAV)$';
  } else if (fieldName === 'appImg') {
    expr = '(.*?).(jpg|jpeg|JPG|JPEG|png|PNG)$';
  }
  return new RegExp(expr);
}

export function getCSVData(file, headers) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const allTextLines = reader.result.split(/\r\n|\n/);

      if (allTextLines) {
        allTextLines.splice(0, 1);
        const data = [];
        allTextLines.forEach((line) => {
          if (!headers) {
            headers = line.split(';');
          } else {
            const lineData = line.split(';');
            const item = {
              [headers[0].field || headers[0]]: lineData[0],
              [headers[1].field || headers[1]]: lineData[1]
            };

            data.push(item);
          }
        });

        resolve(data);
      }
    };
    reader.onerror = (e) => {
      resolve([]);
    };
    reader.readAsText(file);
  });
}

export function validateCSV(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const allTextLines = reader.result.split(/\r\n|\n/);
      const headers =
        allTextLines && allTextLines.length > 2
          ? allTextLines[0].split(';')
          : [];
      resolve(headers);
    };
    reader.onerror = (e) => {
      resolve([]);
    };
    reader.readAsText(file);
  });
}

export function exportarCSV(title, subtitles) {
  //console.log(subtitles);
  let csvData = `Personaje;Diálogo;Tiempo\r\n`;

  subtitles.forEach((l) => {
    csvData += l.isOriginal
      ? `ORIGINAL;;${l.seekPoint ? l.seekPoint / 1000 : ''}\r\n`
      : `${l.character.title};${l.text};${
          l.seekPoint ? l.seekPoint / 1000 : ''
        }\r\n`;
  });

  const blob = new Blob(['\uFEFF' + csvData]); //, { type: ' type: "text/csv;charset=UTF-8"' });
  saveAs(blob, `${title}.csv`);
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function isRetakeEnabled(kind) {
  const retakeEnbld = ['MOVIE', 'AUDIOBOOK'];
  return retakeEnbld.includes(kind) ? true : false;
}

export function isShowWatermark(kind) {
  const retakeEnbld = ['MOVIE'];
  return retakeEnbld.includes(kind) ? true : false;
}

export function getProfile() {
  try {
    const profile = JSON.parse(localStorage.getItem('profile'));
    return new Map({ profile });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getPreferences() {
  try {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    return new Map({ preferences });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function updateProfile(preferences) {
  try {
    const profile = JSON.parse(localStorage.getItem('profile'));
    profile.preferences = preferences;
    localStorage.setItem('profile', JSON.stringify(profile));
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function mapRole(role) {
  switch (role) {
    case 'Manager':
      return 'managers';
    case 'Client':
      return 'clients';
    case 'Producer':
      return 'producers';
    case 'Translator':
      return 'translators';
    case 'Tech Mixer':
      return 'techMixers';
    case 'Director':
      return 'directors';
    case 'Voice Actor':
      return 'voiceActors';
    default:
      break;
  }
}

export function secsToMinSec(secs) {
  const m = Math.floor(secs / 60);
  const s = secs % 60;

  return `${m}m ${s}s`;
}

export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = (millis % 60000).toFixed(0);
  const divi = seconds / 1000;
  return minutes + ':' + (divi < 10 ? '0' : '') + seconds;
}

export function msToTime(duration) {
  let milliseconds = parseInt((duration % 1000) / 10);
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  minutes = minutes + hours * 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  milliseconds = milliseconds < 10 ? '0' + milliseconds : milliseconds;
  return minutes + ':' + seconds + '.' + milliseconds;
}

export function millisToSeconds(millis) {
  var seconds = (millis / 1000).toFixed(2);
  return parseInt(seconds);
}

export function getLastState(role) {
  let state = '';
  switch (role) {
    case 'MANAGER':
      state = 'PENDING_EXPORT';
      break;
    case 'PRODUCER':
      state = 'CLOSED';
      break;
    case 'TRANSLATOR':
      state = 'CLOSED';
      break;
    case 'TECHMIXER':
      state = 'LIVE';
      break;
    case 'DIRECTOR':
      state = 'CLOSED';
      break;
    case 'VOICEACTOR':
      state = 'CLOSED';
      break;
    case 'CLIENT':
      state = 'CLOSED';
      break;
    default:
      state = 'CLOSED';
      break;
  }
  return state;
}

export function getDefaultRoute(role) {
  let defaultRoute = '';
  switch (role) {
    case 'MANAGER':
    case 'CLIENT':
      defaultRoute = '/projects/activeProjects';
      break;
    case 'PRODUCER':
      defaultRoute = '/projects/activationPending';
      break;
    case 'TRANSLATOR':
      defaultRoute = '/projects/translationPending';
      break;
    case 'DIRECTOR':
      defaultRoute = '/projects/textReviewPending';
      break;
    case 'TECHMIXER':
      defaultRoute = '/projects/pendingTaking';
      break;
    case 'VOICEACTOR':
      defaultRoute = '/projects/dubPending';
      break;
    case 'ADMIN':
      defaultRoute = '/projects';
      break;
    default:
      defaultRoute = '/';
      break;
  }
  return defaultRoute;
}

export function showBreadCrumb(user) {
  let show = false;
  const userRole = user && user.role ? user.role.handle : null;
  // console.log("userRole",userRole);
  if (userRole) {
    if (
      userRole === 'MANAGER' ||
      userRole === 'PRODUCER' ||
      userRole === 'DIRECTOR' ||
      userRole === 'CLIENT'
    ) {
      show = true;
    }
  }
  return show;
}
export function mapUserRole(role) {
  switch (role) {
    case 'manager':
      return {
        handle: 'MANAGER',
        title: 'Managers'
      };

    case 'producer':
      return {
        handle: 'PRODUCER',
        title: 'Producers'
      };

    case 'translator':
      return {
        handle: 'TRANSLATOR',
        title: 'Translators'
      };

    case 'techmixer':
      return {
        handle: 'TECHMIXER',
        title: 'Tech Mixers'
      };

    case 'director':
      return {
        handle: 'DIRECTOR',
        title: 'Directors'
      };

    case 'voiceactor':
      return {
        handle: 'VOICEACTOR',
        title: 'Voice Actors'
      };
    case 'client':
      return {
        handle: 'CLIENT',
        title: 'Clients'
      };
    case 'admin':
      return {
        handle: 'ADMIN',
        title: 'Admin'
      };

    default:
  }
}

export function isInteger(str) {
  var pattern = /^\d+$/;
  return pattern.test(str);
}

export function getAssignedActor(actors) {
  const assigned = actors.filter((actor) => actor.status === 'ACTIVE');
  if (assigned.length > 0) {
    return assigned[0].user.firstName + ' ' + assigned[0].user.lastName;
  }
  return '';
}

export function getCharacterTakes(takes) {
  return takes.length > 0 ? takes.length : '';
}

export function getStatusText(status) {
  let statusText = status;
  switch (status) {
    case 'PENDING_QA':
      statusText = 'Pending QA';
      break;
    case 'PENDING_DUBBING':
      statusText = 'Pending Dubbing';
      break;
    case 'PENDING_EXPORT':
      statusText = 'Pending Export';
      break;
    case 'PENDING_TECH_RETAKE':
      statusText = 'Pending Tech Retake';
      break;
    case 'PENDING_TECH':
      statusText = 'Pending Tech';
      break;
    default:
      break;
  }
  return statusText;
}

export const getStates = function (key) {
  const states = [
    { title: 'Pending Setup', handle: 'PENDING_SETUP' },
    { title: 'Pending Translation', handle: 'PENDING_TRANSLATION' },
    { title: 'Pending Text Review', handle: 'PENDING_TEXT_REVIEW' },
    { title: 'Pending Take', handle: 'PENDING_TAKE' },
    { title: 'Pending Cast Assignment', handle: 'PENDING_CAST_ASSIGNMENT' },
    { title: 'Pending Cast Review', handle: 'PENDING_CAST_REVIEW' },
    { title: 'Waiting Dubbers', handle: 'WAITING_DUBBERS' },
    { title: 'Pending QA', handle: 'PENDING_QA' },
    { title: 'Pending QA Retake', handle: 'PENDING_QA_RETAKE' },
    { title: 'Pending Tech Retake', handle: 'PENDING_TECH_RETAKE' },
    { title: 'Pending Export', handle: 'PENDING_EXPORT' },
    { title: 'Dubbing in Progress', handle: 'PROGRESS_DUBBING' },
    { title: 'Live', handle: 'LIVE' },
    { title: 'Closed', handle: 'CLOSED' }
  ];
  return states;
};

export function videoTimeFps(currSeconds, duration = currSeconds, fps = 24) {
  let horas = Math.floor(currSeconds / 3600);
  let minutos = Math.floor((currSeconds % 3600) / 60);
  let segundos = Math.floor((currSeconds % 3600) % 60);
  let resto = ((currSeconds % 3600) % 60) - segundos;
  let fotograma = Math.floor(resto * fps);

  const curTime = `${horas < 10 ? '0' : ''}${horas}:${
    minutos < 10 ? '0' : ''
  }${minutos}:${segundos < 10 ? '0' : ''}${segundos}:${
    fotograma < 10 ? '0' : ''
  }${fotograma}`;

  return duration ? `${curTime}` : '00:00:00:00';
}

export function videoTime(currTime, duration) {
  const curTime = parseFloat(currTime / 100).toFixed(2);
  const vidLength = parseFloat(duration / 100).toFixed(2);
  return duration ? `${curTime}/${vidLength}` : '0.0/0.0';
}

export function videoTimecode(currSeconds, duration) {
  // var minutes = Math.floor(millis / 60000);
  // var seconds = ((millis % 60000)).toFixed(0);
  // return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;

  let minutes = Math.floor(currSeconds / 60);
  let seconds = (currSeconds % 60).toFixed(0);
  const curTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  let vidLengthMinutes = Math.floor(duration / 60);
  let vidLengthSeconds = (duration % 60).toFixed(0);
  const vidLength = `${vidLengthMinutes}:${
    vidLengthSeconds < 10 ? '0' : ''
  }${vidLengthSeconds}`;

  return duration ? `${curTime}/${vidLength}` : '0.0/0.0';
}

export function showTutoial(screen, preferences) {
  const index = preferences.findIndex(
    (preference) => preference.screenId === screen
  );
  return preferences[index].doNotShow;
}

export function setDashboardVideoState(val) {
  try {
    localStorage.setItem('vvz-dash-video', val);
  } catch (err) {}
}

export function getDashboardVideoState() {
  try {
    return localStorage.getItem('vvz-dash-video');
  } catch (err) {}
}

export function checkIfCanEdit(project, field) {
  try {
    let disable = true;
    switch (field) {
      case 'director':
        let dirStates = ['PENDING_SETUP', 'PENDING_TRANSLATION'];
        if (project && dirStates.indexOf(project.state) > -1) {
          disable = false;
        }
        break;
      case 'techMixers':
        let techStates = [
          'PENDING_SETUP',
          'PENDING_TRANSLATION',
          'PENDING_TEXT_REVIEW',
          'PENDING_TAKE',
          'PENDING_CAST_ASSIGNMENT',
          'PENDING_CAST_REVIEW',
          'WAITING_DUBBERS'
        ];
        if (project && techStates.indexOf(project.state) > -1) {
          disable = false;
        }
        break;
      case 'translators':
        let transStates = ['PENDING_SETUP', 'PENDING_TRANSLATION'];
        if (project && transStates.indexOf(project.state) > -1) {
          disable = false;
        }
        break;
      case 'deadline':
        let deadLineStates = ['PENDING_SETUP', 'PENDING_TRANSLATION'];
        if (project && deadLineStates.indexOf(project.state) > -1) {
          disable = false;
        }
        break;
      case 'video':
        let videoStates = [
          'PENDING_SETUP',
          'PENDING_TRANSLATION',
          'PENDING_TEXT_REVIEW',
          'PENDING_TAKE'
        ];
        if (project && videoStates.indexOf(project.state) > -1) {
          disable = false;
        }
        break;
      case 'text':
        let textStates = ['PENDING_SETUP', 'PENDING_TRANSLATION'];
        if (project && textStates.indexOf(project.state) > -1) {
          disable = false;
        }
        break;
      default:
        disable = false;
    }
    // console.log('disabled', field, disable);
    return disable;
  } catch (err) {}
}

export const getTimerTracker = (
  el,
  isPlaying,
  modoReloj,
  prevTake,
  currentTime,
  duration
) => {
  changeColorTimer(el, modoReloj, isPlaying);
  const startTime = prevTake
    ? prevTake[prevTake.length - 1].seekPoint / 1000 - 5
    : 0;
  let time = startTime + currentTime;
  if (modoReloj) time = duration - currentTime;

  return videoTimeFps(time); //, vid.duration, fps);
};

export const changeColorTimer = (el, modoReloj, isPlaying) => {
  let alpha = 1;
  if (!isPlaying) alpha = 1 / 2;
  if (modoReloj) el.style.color = `rgba(255,0,0,${alpha})`;
  else el.style.color = `rgba(0,255,0,${alpha})`;
};
